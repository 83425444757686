import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import App from "../pages/app";
import { useGlobal } from "reactn";
import getLang from "../components/scripts/getLang";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as Style from "../css/templateStyle";

const SmallTalkPost = ({ data }) => {
  let [english] = useGlobal("English");
  data = getLang(english, data.allContentfulSmallTalks);
  const { rubrik, bild, text } = data[0].node;

  return (
    <App>
      <SEO title={rubrik} />
      <Style.SmallTalkPostContainer>
        <Style.ArrowBack color={"black"}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => window.history.back()}
          />
        </Style.ArrowBack>
        <Img fluid={bild.fluid} />
        <Style.PostContent>
          <h1>{rubrik} </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: text.childContentfulRichText.html
            }}
          />
        </Style.PostContent>
      </Style.SmallTalkPostContainer>
    </App>
  );
};
export default SmallTalkPost;

export const pageQuery = () => {
  return graphql`
    query ContentfulSmallTalkPostBySlug($slug: String!) {
      allContentfulSmallTalks(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            node_locale
            rubrik
            bild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            text {
              childContentfulRichText {
                html
              }
            }
            slug
          }
        }
      }
    }
  `;
};
